<template>
  <div class="pageA">
    <div class="active">
      <van-row type="flex" justify="center">
        <van-col span="24">
          <h1 style="text-align: center">{{activeInfo.name}}</h1>
        </van-col>
      </van-row>
      <van-row type="flex" justify="center">
        <van-col span="24" style="text-align:center;font-size:0.5rem;color:red">
          {{activeInfo.beginTime}}~{{activeInfo.endTime}}
        </van-col>
      </van-row>
      <van-row type="flex" justify="center">
        <van-col span="24">
          <van-divider
            :style="{
              color: '#1989fa',
              borderColor: '#1989fa',
              padding: '0 16px',
            }"
            dashed
            >活动说明</van-divider>
          <div
            style="height: 200px; border: 1px red solid; margin: 20px 0"
          >{{activeInfo.rule}}</div>
        </van-col>
      </van-row>
      <van-row type="flex" justify="center">
        <van-col span="24">
          <div class="sg">
            <van-grid
              v-for="item in coupons"
              :key="item.id"
              :column-num="1"
              :center="false"
              style="margin: 15px;"
            >
              <van-grid-item @click="selectq" style="border: 1px red solid">
                <div class="couponName">
                  <div class="couponam">{{item.amount}}</div>
                  <div class="couponam">{{item.name}}</div>
                </div>
              </van-grid-item>
            </van-grid>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "pageA",
  data() {
    return {
      activeInfo:{
        appCoutormInfoId:null,
        beginTime:null,
        endTime:null,
        name:null,
        reserves:null,
        rule:null,
        uiFilePath:null,
      },
      coupons:[]
    }
  },
  created() {
      this.getmessage();
  },
  methods: {
    getmessage(){
        this.$axios.get(`http://ebuy.hillapi.com/ebuy/api/admin/activity/page?id=5`).then(response=>{
            this.activeInfo=response.rows[0];
            this.$axios.get('http://ebuy.hillapi.com/ebuy/api/admin/couponConfig/page?activityId=5').then(response=>{
              this.coupons=response.rows;
            })
        })
    },
    selectq() {
      this.$dialog.alert({ theme: "round-button", message: "领取成功" });
      this.$router.push('/menuAll')
    },
  },
};
</script>
<style>
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}
.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #ee0a24;
}
.sg {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  height: 300px;
  margin: 5px 0;
}
.active {
  margin: 5px;
  padding-bottom: 20px;
}
.couponName{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.couponam{
  font-weight: bold;
  font-size: 2rem;
  color: red;
}
</style>