import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginActive from '../view/LoginActive.vue';
import pageA from '../view/message/pageA'
Vue.use(VueRouter)
const routes = [
  {
    path:'/',
    name:'proList',
    component:()=>import('../view/message/proList')
  },
  {
    path:'/prodetail/:id/:mid',
    name:'prodetail',
    component:()=>import('../view/message/proDetail')
  },
  {
    path:'/placeorder',
    name:'placeorder',
    component:()=>import('../view/message/placeOrder')
  },
  {
    path: '/login',
    name: 'LoginActive',
    component: LoginActive,
  },
  {
    path: '/pageA',
    name: 'pageA',
    component: pageA
  },
  {
    path: '/pageB',
    name: 'pageB',
    component: () => import('../view/message/pageB')
  },
  {
    path: '/pageC/:id',
    name: 'pageC',
    component: () => import('../view/message/pageC')
  },
  {
    path: '/pageD',
    name: 'pageD',
    component: () => import('../view/message/pageD')
  },
  {
    path: '/pageE',
    name: 'pageE',
    component: () => import('../view/message/pageE')
  },
  {
    path: '/orderdetail/:id',
    name: 'orderdetail',
    component: () => import('../view/message/orderdetail')
  },

  {
    path: '/userpage',
    name: 'userpage',
    component: () => import('../view/message/userpage')
  },
  {
    path: '/userorderdetail/:id',
    name: 'userorderdetail',
    component: () => import('../view/message/userorderdetail')
  },
  {
    path: '/merchantpage',
    name: 'merchantpage',
    component: () => import('../view/message/merchantPage'),
    children:[
      {
        path: '/merchantpage/orderlist',
        name: 'orderlist',
        component: () => import('../view/message/orderList')
      },
      {
        path: '/merchantpage/merchantmessage',
        name: 'merchantmessage',
        component: () => import('../view/message/merchantmessage')
      },
      {
        path: '/merchantpage/merchantqrcode',
        name: 'merchantqrcode',
        component: () => import('../view/message/merchantQrCode')
      },
    ]
  },
  {
    path: '/menuAll',
    name: 'menuAll',
    component: () => import('../view/menuAll')
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router