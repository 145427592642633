import Vue from 'vue'
import App from './App'
import {Pagination,NumberKeyboard,ShareSheet,Tabbar,TabbarItem,Toast,Notify,DropdownMenu, DropdownItem,Area,List,Step,Steps,NavBar,CellGroup,Cell,Calendar,Button,Form,Field,Icon,Col,Row,CountDown,Grid, GridItem,Card,Dialog,Divider,Image as VanImage,Swipe,SwipeItem,ImagePreview,SubmitBar,RadioGroup, Radio,DatetimePicker,Popup,Checkbox} from 'vant';
import 'vant/lib/index.css';
import './api/http.js'
import router from './router'
Vue.use(Pagination)
Vue.use(NumberKeyboard)
Vue.use(ShareSheet)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Toast)
Vue.use(Notify)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Col)
Vue.use(Row)
Vue.use(CountDown)
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Card);
Vue.use(Dialog);
Vue.use(Divider)
Vue.use(VanImage)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(ImagePreview)
Vue.use(SubmitBar)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Checkbox)
Vue.use(Calendar)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(NavBar)
Vue.use(Steps)
Vue.use(Step)
Vue.use(List)
Vue.use(Area)
Vue.config.productionTip = false
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import 'amfe-flexible'
// Vue.use(ElementUI)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
