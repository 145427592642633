<template>
  <div class="Login">
    <div class="formlogin">
    <van-form @submit="onSubmit">
      <van-field
        v-model="formLogin.username"
        name="用户名"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="formLogin.password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <van-field
        v-model="formLogin.code"
        center
        clearable
        type="digit"
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #extra>
          <img :src="codeUrl" @click="getCode"  class="imgh"/>
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>      
    </div>

  </div>
</template>

<script>
import {login,codeapi,testtoken} from '@/api/login'
export default {
  name: "LoginActive",
  data() {
    return {
      codeUrl:'',
      formLogin:{
        username:'',
        password:'',
        code:null,
        uuid:null
      },
    };
  },
  created() {
    this.getCode();
  },
  methods: {
    getCode() {
      codeapi().then(response=>{
        this.codeUrl = "data:image/gif;base64," + response.captcha;
        this.formLogin.uuid = response.uuid;
      })
    },
    onSubmit() {
      login({
            username: this.formLogin.username,
            password: this.formLogin.password,
            verCode: this.formLogin.code,
            uuid: this.formLogin.uuid
        }).then(response=>{
          console.log(response)
          if(response.accessToken){
            sessionStorage.setItem('token',JSON.stringify(response.accessToken))
            sessionStorage.setItem('userId',JSON.stringify(response.id))
            testtoken({headers:{
              'Authorization':response.accessToken
            }}).then(res=>{
              let merchant=res.groupTypes.some(item=>{
                return item.id===5;
              })
              let user=res.groupTypes.some(item=>{
                return item.id===4;
              })
              if(user){
                this.$router.push({path:'/userpage'})
              }else if(merchant){
                this.$router.push({path:'/merchantpage/orderlist'})
              }
            })            
          }else{
            this.$notify({ type: 'warning', message:"登录失败\n"+response.message});
          }
      }).catch(res=>{
        console.log(res)
        this.$notify({ type: 'warning', message:"用户不存在或账户密码错误!"});
      })
    },
  },
};
</script>
<style scoped>
.formlogin {
  position: absolute;
  top: 33.8%;
  text-align: center;
  margin: auto;
}
.imgh{
  width:100px
}
</style>
